/* import '../App.css'; */

import Video from '../components/video.js';
/* import 'bootstrap/dist/css/bootstrap.min.css'; */
/* import TopBar from '../components/top_bar.js'; */
/* import NavBar from '../components/Navbar.js';
import Cat from '../components/categorias.js'; */
import Ite from '../components/items_new.js';
import Portafolio from '../components/portafolio2.js';
function App() {

  const publica = '6LfiEjMpAAAAALy3S50a_dAjAE2B_xj0kT-_ITbX';
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
  script.async = true;
  script.id = 'rc'
  const cadenaConScript = script.text;
  cadenaConScript.replace(/<script.*>.*<\/script>/ims, " ");
  
  return (
<>
<Video nombre="../assets/video/contacto.mp4" caption="demos" replay={true} string=""/>
{/* <TopBar></TopBar> */}
{/* <NavBar></NavBar>
<Cat></Cat> */}
{/* <Ite></Ite> */}<Portafolio/></>
 );
}

export default App;