import React, { useState } from 'react';
import '../App.css';
import * as Icon from 'react-bootstrap-icons';
import Video from '../components/video.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Wsp from '../components/wsp.js';
import '../assets/mp3/applite.mp3';
import { Link } from 'react-router-dom';
function App() {

const publica = '6LfiEjMpAAAAALy3S50a_dAjAE2B_xj0kT-_ITbX';
const script = document.createElement('script');
script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
script.async = true;
script.id = 'rc'
const cadenaConScript = script.text;
cadenaConScript.replace(/<script.*>.*<\/script>/ims, " ");

return (
  <>
  <Video  nombre="../assets/video/applite.mp4" 
          caption="portada" 
          replay={true} 
          string={
            <div style={{ zIndex: 4, position: 'fixed', top: '10px', right: '10px' }}>
         <Link to='/nosotros'><Icon.CameraVideoFill id='volumen' size={25} color='white'></Icon.CameraVideoFill></Link>
            </div>} 
    />
    <Wsp></Wsp></>
);
}

export default App;