import React from 'react';
import '../css/NewCarousel.css';
/* import '../js/jquery/jquery.min.js'; */


import '../css/all.min.css';
import '../lib/animate/animate.min.css';
import '../lib/owlcarousel/assets/owl.carousel.min.css';
import '../css/style.css';
import '../webfonts/fa-brands-400.ttf';
import '../webfonts/fa-brands-400.woff2';
import '../webfonts/fa-regular-400.ttf';
import '../webfonts/fa-regular-400.woff2';
import '../webfonts/fa-solid-900.ttf';
import '../webfonts/fa-solid-900.woff2';   
import '../webfonts/fa-brands-400.eot';  
import '../webfonts/fa-solid-900.eot';  
import '../webfonts/fa-brands-400.woff';  
import '../webfonts/fa-regular-400.eot';  
import '../webfonts/fa-regular-400.woff';  
import '../webfonts/fa-brands-400.svg';  
import '../webfonts/fa-regular-400.svg';  
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

function Menu(props) {
  return (
<div className="container-fluid mb-3">
    <div className="row px-xl-5">
        <div className="col-lg-8">
            <div id="header-carousel" className="carousel slide carousel-fade mb-30 mb-lg-0" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#header-carousel" data-slide-to="0" className="active"></li>
                    <li data-target="#header-carousel" data-slide-to="1"></li>
                    <li data-target="#header-carousel" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item position-relative active" style={{height: '430px'}}>
                        <LazyLoadImage className="position-absolute w-100 h-100" src={require(`../assets/${props.carouselcarpeta1}/carousel-${props.numero1}.jpg`)} style={{objectFit: 'cover'}} key={props.numero1}/>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{maxWidth: '700px'}}>
                                <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown" style={{textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black',color: 'white'}}>{props.carouseltitulo1}</h1>
                                <p className="mx-md-5 px-5 animate__animated animate__bounceIn">{props.carouseltexto1}</p>
                                <Link className="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" to="#" style={{visibility: props.visiblebtn1}}>{props.carouselboton1}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item position-relative" style={{height: '430px'}}>
                        <LazyLoadImage className="position-absolute w-100 h-100" src={require(`../assets/${props.carouselcarpeta1}/carousel-${props.numero2}.jpg`)} style={{objectFit: 'cover'}} key={props.numero2}/>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{maxWidth: '700px'}}>
                                <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown" style={{textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black',color: 'white'}}>{props.carouseltitulo2}</h1>
                                <p className="mx-md-5 px-5 animate__animated animate__bounceIn">{props.carouseltexto2}</p>
                                <Link className="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" to="#" style={{visibility: props.visiblebtn2}}>{props.carouselboton2}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item position-relative" style={{height: '430px'}}>
                        <LazyLoadImage className="position-absolute w-100 h-100" src={require(`../assets/${props.carouselcarpeta1}/carousel-${props.numero3}.jpg`)} style={{objectFit: 'cover'}} key={props.numero3}/>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{maxWidth: '700px'}}>
                                <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown" style={{textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black',color: 'white'}}>{props.carouseltitulo3}</h1>
                                <p className="mx-md-5 px-5 animate__animated animate__bounceIn">{props.carouseltexto3}</p>
                                <Link className="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" to="#" style={{visibility: props.visiblebtn3}}>{props.carouselboton3}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-4">
            <div className="product-offer mb-30" style={{height: '200px'}}>
                <LazyLoadImage className="img-fluid" src={require(`../assets/${props.carpetaa1}/producto-${props.numeroa1}.jpg`)}  alt="" key={props.numeroa1}/>
                <div className="offer-text">
                    <h6 className="text-white">{props.textoa1}</h6>
                    <h3 className="text-white mb-3" style={{textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black',color: 'white'}}>{props.tituloa1}</h3>
                    <Link to="" className="btn btn-primary" style={{visibility: props.visiblebotona1}}>{props.botona1}</Link>
                </div>
            </div>
            <div className="product-offer mb-30" style={{height: '200px'}}>
                <LazyLoadImage className="img-fluid" src={require(`../assets/${props.carpetab2}/producto-${props.numerob2}.jpg`)} alt="" key={props.numerob2}/>
                <div className="offer-text">
                    <h6 className="text-white ">{props.textob2}</h6>
                    <h3 className="text-white mb-3" style={{textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black',color: 'white'}}>{props.titulob2}</h3>
                    <Link to="" className="btn btn-primary" style={{visibility: props.visiblebotonb2}}>{props.botonb2}</Link>
                </div>
            </div>
        </div>
    </div>
</div>  );
}

export default Menu;