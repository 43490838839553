import '../App.css';
import Video from '../components/video.js';
import * as Icon from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductosCarousel from '../components/carousel.js';
import Afiche from '../components/afiches.js';
/* import Portafolio from '../components/portafolio.js'; */
function App() {
        const publica = '6LfiEjMpAAAAALy3S50a_dAjAE2B_xj0kT-_ITbX';
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
        script.async = true;
        script.id = 'rc'
        const cadenaConScript = script.text;
        cadenaConScript.replace(/<script.*>.*<\/script>/ims, " ");
  return (
<>
<div >
<Video nombre="../assets/video/contacto.mp4" caption="productos" replay={true} string={ <div></div>}/>
</div>

<div >
<Afiche numero1='1'
        titulo1='WEB PROFESIONAL'
        texto1='Creación de páginas y sitios web. Desarrollo de sistemas avanzados a la medida.'
        carpeta1='productos'
        boton1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton1= 'hidden'
        numero2='2'
        titulo2='HOSTING PYME'
        texto2='Hosting de alto rendimiento recomendado para sistemas informáticos multiusuarios.'
        carpeta2='productos'
        boton2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton2= 'hidden'/>

<ProductosCarousel

numero1='1'
carouseltitulo1='¡Desarrollos Adaptables a Dispositivos!'
carouseltexto1='Análisis, Diseño, Desarrollo, Integración, Migración, Mantención, Soporte, Respaldos, Mejoras y Actualizaciones Web.'
carouselcarpeta1='productoscarousel'
carouselboton1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
visiblebtn1= 'hidden'

numero2='2'
carouseltitulo2='SOFTWARE'
carouseltexto2='Análisis, Diseño, Desarrollo, Integración, Migración, Mantención, Soporte, Respaldos, Mejoras y Actualizaciones.'
carouselcarpeta2='productoscarousel'
carouselboton2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
visiblebtn2= 'hidden'

numero3='3'
carouseltitulo3='¿Necesita un aliado a La medida?'
carouseltexto3=''
carouselcarpeta3='productoscarousel'
carouselboton3={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
visiblebtn3= 'hidden'

        numeroa1='5'
        tituloa1='HOSTING EMPRENDEDOR'
        textoa1='Hosting para páginas y sitios web.'
        carpetaa1='productos'
        botona1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visiblebotona1= 'hidden'
        numerob2='6'
        titulob2='Migración de Sistemas'
        textob2=''
        carpetab2='productos'
        botonb2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visiblebotonb2= 'hidden'/>

<Afiche numero1='3'
        titulo1='WEB ADMINISTRABLE'
        texto1='Creación de un sistema de gestión de contenido mediante una plantilla predefinida.'
        carpeta1='productos'
        boton1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton1= 'hidden'
        numero2='4'
        titulo2='Open Graph'
        texto2='Las etiquetas de Open Graph hacen que tu contenido aparezca en formato enriquecido en redes sociales, dando confianza a tus clientes. Esto ayuda a que los usuarios vean de qué se trata tu contenido y los atrae si les interesa.'
        carpeta2='productos'
        boton2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton2= 'hidden'/>
        
<Afiche numero1='7'
        titulo1=''
        texto1=''
        carpeta1='productos'
        boton1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton1= 'hidden'
        numero2='8'
        titulo2=''
        texto2=''
        carpeta2='productos'
        boton2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton2= 'hidden'/>
                
<Afiche numero1='9'
        titulo1='Desarrollo de API'
        texto1=''
        carpeta1='productos'
        boton1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton1= 'hidden'
        numero2='10'
        titulo2=''
        texto2=''
        carpeta2='productos'
        boton2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
        visibleboton2= 'hidden'/>{/* <Portafolio/> */}
</div>
</>
 );
}

export default App;