import { useEffect, useState } from 'react';
import '../css/portafolio2.css';
import data from "../data/proyectos.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
function App() {

  const [productos, setProductos] = useState([]);
const pedirproducto = () => {
  return new Promise((resolve, reject)=>{
      resolve(data);
  })
}
useEffect(()=>{
  pedirproducto()
  .then((res)=>{
    /* productos = res; */
    setProductos(res);
  })
},[])

  return (<><section className="page-section portfolio" id="portfolio"  style={{position: 'relative'}}>
    <div className="container">
      <h2 className="page-section-heading text-center text-uppercase mb-0">Proyectos</h2>
      <div className="divider-custom">
        <div className="divider-custom-line"></div>
        <div className="divider-custom-icon"><Icon.LayoutWtf style={{color: 'white'}}></Icon.LayoutWtf></div>
        <div className="divider-custom-line"></div>
      </div>
      <div className="row justify-content-center">

      {
productos.length > 0 &&
productos.map((producto)=>{
    const nf = new Intl.NumberFormat("es-CL");
    return (
      <Link className="col-md-6 col-lg-4 mb-5" to={producto.url} target='_blank'> 
            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target={`#portfolioModal${producto.id}`}>
              <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
            <div className="portfolio-item-caption-content text-center text-white">
                  <Icon.Link45deg style={{color: 'white', fontSize: '28px'}}></Icon.Link45deg>
                  {producto.titulo}
                </div> 
              </div>
              <LazyLoadImage className="img-fluid" src={`../assets/img/portfolio/portfolio-${producto.id}.png`} alt="..." />
            </div></Link>)
})
  } </div>
    </div>
  </section></>)
}

export default App;
