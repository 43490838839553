import React from 'react'
import ReactPlayer from 'react-player'
/* 
import Container from 'react-bootstrap/Container'; */
import '../css/Video2.css';

function Video() {
 return (
<>
 {/*  <video autoPlay loop muted>
    <source src = "assets/video/applite.cl3.mp4" type = 'video/mp4' />
  </video>  
  <div className='content'>
    <h1>dsfsdfdsfdsfdsf</h1>
  </div> */}
  <ReactPlayer id='video2'
  className='react-player'
    url={'../assets/video/applite-intro.mp4'}
    width='100%'
     controls
  ></ReactPlayer>
</>
 );
}


export default Video;