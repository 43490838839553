import '../App.css';
import Video from '../components/video.js';
import * as Icon from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import NosotrosCarousel from '../components/carousel.js';
import Nvideo from '../components/Reactplayer.js';
function App() {
  const publica = '6LfiEjMpAAAAALy3S50a_dAjAE2B_xj0kT-_ITbX';
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
  script.async = true;
  script.id = 'rc'
  const cadenaConScript = script.text;
  cadenaConScript.replace(/<script.*>.*<\/script>/ims, " ");

  return (<div style={{ marginTop: '50px' }}>

    <div ><Video nombre="../assets/video/contacto.mp4" caption="productos" replay={true} string={<div></div>} /></div>
    <div><Nvideo /></div>
    <div ><NosotrosCarousel numero1='1'
      carouseltitulo1='Misión'
      carouseltexto1='Ofrecer soluciones de tecnología nuevas, y efectivas. Damos asesoría experta, ejecutamos proyectos con calidad y nos enfocamos en la excelencia y la satisfacción del cliente.'
      carouselcarpeta1='nosotroscarousel'
      carouselboton1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
      visiblebtn1='hidden'

      numero2='2'
      carouseltitulo2='Visión'
      carouseltexto2='Ser líderes en la creación de sistemas informáticos. Aspiramos a ser un socio clave para nuestros clientes, contribuyendo a su crecimiento y desarrollo con nuestra excelencia en sistemas informáticos.'
      carouselcarpeta2='nosotroscarousel'
      carouselboton2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
      visiblebtn2='hidden'

      numero3='3'
      carouseltitulo3='Objetivo'
      carouseltexto3='Ser un aliado confiable para ti, ofreciendo servicios completos de informática, solucionando tus necesidades tecnológicas y desarrollando proyectos eficientes, de calidad, seguros y que te satisfagan plenamente.'
      carouselcarpeta3='nosotroscarousel'
      carouselboton3={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
      visiblebtn3='hidden'
      numeroa1='1'
      tituloa1='¡Desarrollos Adaptables a Dispositivos!'
      textoa1='Diseño, Desarrollo, Integración, Migración, Actualización y Mantención de Sistemas.'
      carpetaa1='nosotros'
      botona1={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
      visiblebotona1='hidden'
      numerob2='2'
      titulob2='Codificamos tus ideas, conectándolas con resultados.'
      textob2='Desarrollo de Sistemas'
      carpetab2='nosotros'
      botonb2={<Icon.VolumeUpFill size={25}></Icon.VolumeUpFill>}
      visiblebotonb2='hidden' /></div>
  </div>
  );
}

export default App;