import React from 'react';
import '../css/NewCarousel.css';
import '../css/all.min.css';
import '../lib/animate/animate.min.css';
import '../lib/owlcarousel/assets/owl.carousel.min.css';
import '../webfonts/fa-brands-400.ttf';
import '../webfonts/fa-brands-400.woff2';
import '../webfonts/fa-regular-400.ttf';
import '../webfonts/fa-regular-400.woff2';
import '../webfonts/fa-solid-900.ttf';
import '../webfonts/fa-solid-900.woff2';   
import '../webfonts/fa-brands-400.eot';  
import '../webfonts/fa-solid-900.eot';  
import '../webfonts/fa-brands-400.woff';  
import '../webfonts/fa-regular-400.eot';  
import '../webfonts/fa-regular-400.woff';  
import '../webfonts/fa-brands-400.svg';  
import '../webfonts/fa-regular-400.svg';  
import '../css/style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

function Afiche(props) {
  return (<div className="container-fluid pt-5 pb-3">
  <div className="row px-xl-5">
      <div className="col-md-6">
          <div className="product-offer mb-30" style={{height: '300px'}}>
              <LazyLoadImage className="img-fluid" src={require(`../assets/${props.carpeta1}/producto-${props.numero1}.jpg`)} alt="" key={props.numero1}/>
              <div className="offer-text">
                  <h6 className="text-white " >{props.texto1}</h6>
                  <h3 className="text-white mb-3" style={{textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black',color: 'white'}}>{props.titulo1}</h3>
                  <Link to="" className="btn btn-primary" style={{visibility: props.visibleboton1}}>{props.boton1}</Link>
              </div>
          </div>
      </div>
      <div className="col-md-6">
          <div className="product-offer mb-30" style={{height: '300px'}}>
              <LazyLoadImage className="img-fluid" src={require(`../assets/${props.carpeta2}/producto-${props.numero2}.jpg`)} alt="" key={props.numero2}/>
              <div className="offer-text">
                  <h6 className="text-white " >{props.texto2}</h6>
                  <h3 className="text-white mb-3" style={{textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black',color: 'white'}}>{props.titulo2}</h3>
                  <Link to="" className="btn btn-primary" style={{visibility: props.visibleboton2}}>{props.boton2}</Link>
              </div>
          </div>
      </div>
  </div>
</div>  );
}

export default Afiche;