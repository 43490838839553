import '../App.css';
import Video from '../components/video.js';
/* import * as Icon from 'react-bootstrap-icons'; */
import 'bootstrap/dist/css/bootstrap.min.css';
/* import NosotrosCarousel from '../components/carousel.js'; */
import Nvideo from '../components/ReactplayerYoutube.js';
function App() {
  const publica = '6LfiEjMpAAAAALy3S50a_dAjAE2B_xj0kT-_ITbX';
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
  script.async = true;
  script.id = 'rc'
  const cadenaConScript = script.text;
  cadenaConScript.replace(/<script.*>.*<\/script>/ims, " ");

  return (<div style={{ marginTop: '50px' }}>

    <div ><Video nombre="../assets/video/contacto.mp4" caption="productos" replay={true} string={<div></div>} /></div>
    <div><Nvideo /></div>
  </div>
  );
}

export default App;