import React from 'react'
import ReactPlayer from 'react-player'
/* 
import Container from 'react-bootstrap/Container'; */
import '../css/Video2.css';

function Video() {
 return (
  <div style={{textAlign: 'center'}}>
  <ReactPlayer id='video3'
  className='react-player'
    url={'https://youtu.be/Bt6JfcOCCWo'}
   width='100%'   
   height='100%'
     controls></ReactPlayer>
     </div>
 );
}


export default Video;