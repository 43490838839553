/* import Button from 'react-bootstrap/Button'; */
import Container from 'react-bootstrap/Container';
/* import Form from 'react-bootstrap/Form'; */
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
/* import NavDropdown from 'react-bootstrap/NavDropdown'; */
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
/* import * as Icon from 'react-bootstrap-icons'; */
import '../css/Menu.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Menu() {
  return (
      <Navbar key='sm' expand='sm' className="mb-3" style={{ backgroundColor: 'transparent'}}>
        <Container fluid>
          <Navbar.Brand to="/">
          {/*   <img
              alt=""
              src="/assets/logo/applite.png"
              width="120"
              className="d-inline-block align-top"
            /> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={'offcanvasNavbar-expand-sm'} style={{backgroundColor:'transparent !important'}}/>
          <Navbar.Offcanvas
            id={'offcanvasNavbar-expand-sm'}
            aria-labelledby={'offcanvasNavbarLabel-expand-sm'}
            placement="end"
           
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}  >
                <LazyLoadImage
                  key='1'
                  alt=""
                  src="/assets/logo/applite.png"
                  width="200"
                  className="d-inline-block align-top"
                />{' '}
                {/*             Programador de Cabecera */}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3" >
                <Link to="/" style={{ textDecorationLine: 'none', paddingLeft: '8px', paddingBottom: '3px', color: 'white', textAlign: 'left' }}>{/* <Button variant="primary" size='sm' style={{ width: '120px' }}><Icon.HouseFill size={18} title=' ' ></Icon.HouseFill>  Inicio</Button> */}Inicio</Link>
                <Link to="/nosotros/" style={{ textDecorationLine: 'none', paddingLeft: '8px', paddingBottom: '3px', color: 'white', textAlign: 'left' }}>{/* <Button variant="primary" size='sm' style={{ width: '120px' }}><Icon.EmojiSmile size={18} title=' '></Icon.EmojiSmile>  Nosotros</Button> */}Nosotros</Link>
                <Link to="/productos/" style={{ textDecorationLine: 'none', paddingLeft: '8px', paddingBottom: '3px', color: 'white', textAlign: 'left' }}>{/* <Button variant="primary" size='sm' style={{ width: '120px' }}><Icon.BatteryFull size={18} title=' '></Icon.BatteryFull>  Productos</Button> */}Productos</Link>
                <Link to="/proyectos/" style={{ textDecorationLine: 'none', paddingLeft: '8px', paddingBottom: '3px', color: 'white', textAlign: 'left' }}>{/* <Button variant="primary" size='sm' style={{ width: '120px' }}><Icon.EmojiSmile size={18} title=' '></Icon.EmojiSmile>  Nosotros</Button> */}Proyectos</Link>
                <Link to="/contacto/" style={{ textDecorationLine: 'none', paddingLeft: '8px', paddingBottom: '3px', color: 'white', textAlign: 'left' }}>{/* <Button variant="primary" size='sm' style={{ width: '120px' }}><Icon.Wifi size={18} title=' '></Icon.Wifi>  Contacto</Button> */}Contacto</Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
  );
}

export default Menu;