import React, { useRef, useEffect, useState, useCallback } from 'react';
/* import ReCAPTCHA from 'react-google-recaptcha'; */
import * as Icon from 'react-bootstrap-icons';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/form_contacto.css';
import { Link } from "react-router-dom"; 
import Swal from 'sweetalert2';
import {useForm} from 'react-hook-form';
import contacto from '../assets/mp3/contacto.mp3'
import '../css/contacto.css';
/* var privada = '6LfiEjMpAAAAAMo47ijDfgR2Qa37T-a4TMYit8IK'; */
/* const recaptchaRef = React.createRef(); */
const player = () => {
  var myAudio = document.getElementById("myAudio");  
  myAudio.play();
  } 

 
/*   const cookies = new Cookies();
cookies.set(key1, value1, {secure: true, sameSite: 'none'});
cookies.set(key2, value2, {secure: true, sameSite: 'none'});  
     */
function Formcontacto(){
const {register, formState:{errors},handleSubmit}= useForm();
const [captchaIsDone, SetCaptchaDone] = useState(true);
const publica = '6LfiEjMpAAAAALy3S50a_dAjAE2B_xj0kT-_ITbX';


const form2 = useRef();
/* function onChange(){ */
/*   recaptchaRef.current.execute(); */
/*   SetCaptchaDone(true);
} */
const sendEmail = () => {
/*   e.preventDefault(); */

  emailjs
    .sendForm('service_hdespis', 'template_8ow6ktq', form2.current, {
      publicKey: 'zGjmkbCAr64lbsTe-',
    })
    .then(
      () => {
           console.log('ok');

        Swal.fire({
            position: "center",
            icon: "success",
            title: "Enviado",
            text: "Su Mensaje se envió",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6"/* ,
            timer: 3000 */
          }).then((result) => {
            if (result.isConfirmed) {
              form2.current.reset();
              player();
            }
          });
          
      },
      (error) => {
        console.log('error:', error.text);
        Swal.fire({
            title: 'Error!',
            text: error.text,
            icon: 'error',
            confirmButtonText: 'Cool'
          })
      }
    );
  }

  const eliminaTildes = (e)  => {
    e.preventDefault();
    var titulo = e.target.value.replace("á", 'a').replace("é", 'e').replace("í", 'i').replace("ó", 'o').replace("ú", 'u').replace("ü", 'u').replace("Á", 'A').replace("É", 'E').replace("Í", 'I').replace("Ó", 'O').replace("Ú", 'U').replace("Ü", 'U');
    e.target.value = titulo;
  }


  useEffect( ()=> {
    if(window.grecaptcha){
      SetCaptchaDone(true);
    }else{
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${publica}`;
      script.async = true;
      script.id = 'rc';
      document.head.appendChild(script);
      script.onload = () => {SetCaptchaDone(true)}
    }
    }, [publica])
    
    const executeRecaptcha = useCallback(async (action)=>{
      if(captchaIsDone && window.grecaptcha){
    return await window.grecaptcha.execute(publica, {action})
      }else{
        return executeRecaptcha;
      }
    })


    return (<div className="col-sm-6 col-sm-offset-3 form-box">
        <div className="form-top">
            <div className="form-top-left">
                <h3>Contactanos</h3>
                <p>Envianos un mensaje</p>
            </div>
            <div className="form-top-right">
                <Icon.Envelope></Icon.Envelope><audio id="myAudio">
            <source src={contacto} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
            </div>
        </div>
        <div className="form-text contact-form">
            <form id="form" ref={form2} onSubmit={handleSubmit(()=>{sendEmail();})}>
                <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="user_email">Email</label>
                    <input type="text" {...register("user_email",{pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i, required: true, minLength: 5, maxLength:40})}
                    id="user_email" placeholder="Email..." className="contact-email form-control" autoComplete='email'/>
                    {
                      errors.user_email?.type==="pattern" && (
                          <p style={{color:'yellow'}}>¡Formato del Email no válido!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="required" && (
                          <p style={{color:'yellow'}}>¡Email es requerido!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="minLength" && (
                          <p style={{color:'yellow'}}>¡Email son mínimo 5 caracteres!</p>
                      )
                    }
                    {
                      errors.user_email?.type==="maxLength" && (
                          <p style={{color:'yellow'}}>¡Email largo máximo de 40 caracteres!</p>
                      )
                    }
                </div>
                <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="user_name">Nombre</label>
                    <input type="text" {...register("user_name",{required: true, minLength: 3, maxLength:40})} onChange={eliminaTildes}
                    id="user_name" placeholder="Nombre..." className="contact-nombre form-control" autoComplete='name' name="user_name"/>
                    {
                      errors.user_name?.type==="required" && (
                          <p style={{color:'yellow'}}>¡Su Nombre es requerido!</p>
                      )
                    }
                    {
                      errors.user_name?.type==="minLength" && (
                          <p style={{color:'yellow'}}>Nombre son mínimo 3 caracteres!</p>
                      )
                    }
                    {
                      errors.user_name?.type==="maxLength" && (
                          <p style={{color:'yellow'}}>Nombre largo máximo de 40 caracteres!</p>
                      )
                    }
                </div>
                <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="user_cargo">Cargo</label>
                    <input type="text" {...register("user_cargo",{required: true, minLength: 3, maxLength:40})}
                    id="user_cargo" placeholder="Cargo..." className="contact-cargo form-control"/>
                    {
                      errors.user_cargo?.type==="required" && (
                          <p style={{color:'yellow'}}>¡Su Cargo es requerido!</p>
                      )
                    }
                    {
                      errors.user_cargo?.type==="minLength" && (
                          <p style={{color:'yellow'}}>¡Cargo son mínimo 3 caracteres!</p>
                      )
                    }
                    {
                      errors.user_cargo?.type==="maxLength" && (
                          <p style={{color:'yellow'}}>¡Cargo largo máximo de 40 caracteres!</p>
                      )
                    }
                </div>
                <div className="form-group">
                    <label className="sr-only" style={{color:'white'}} htmlFor="message">Mensaje</label>
                    <textarea  {...register("message",{required: true, minLength: 3, maxLength:200,})}
                    id="message" placeholder="Mensaje..." className="contact-message form-control" ></textarea>
                    {
                      errors.message?.type==="required" && (
                          <p style={{color:'yellow'}}>¡Un Mensaje es requerido!</p>
                      )
                    }
                    {
                      errors.message?.type==="minLength" && (
                          <p style={{color:'yellow'}}>¡Mensaje son mínimo 3 caracteres!</p>
                      )
                    }
                    {
                      errors.message?.type==="maxLength" && (
                          <p style={{color:'yellow'}}>¡Mensaje largo máximo de 200 caracteres!</p>
                      )
                    }
                </div>
       {/*          <ReCAPTCHA
                    sitekey={publica}
                    onChange={onChange}
                  /> */}
                <div className="form-bottom">
                    {captchaIsDone && <button type="submit" className="btn">Enviar Mensaje</button>}
                </div>
            </form>
            <div><Link to="mailto:hola@applite.cl" style={{ textDecorationLine: 'none', paddingLeft: '8px', paddingBottom: '3px', textAlign: 'left',color:'white', fontWeight:'bold' }}><Icon.Envelope></Icon.Envelope> hola@applite.cl</Link></div><div><Link to="mailto:applite.chile@gmail.com" style={{ textDecorationLine: 'none', paddingLeft: '8px', paddingBottom: '3px', textAlign: 'left',color:'white', fontWeight:'bold' }}><Icon.Envelope></Icon.Envelope> applite.chile@gmail.com</Link></div>
        </div>
    </div>);
  }

export default Formcontacto;

