import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default function App(props) {

  return (
    <FloatingWhatsApp style={{color:'black', visibility: props.visivilidad}} phoneNumber="+56 9 9930 2909" 
                        accountName="{APP/LITE}" 
                        chatMessage="Hola, en que puedo ayudarte?"
                        placeholder="Escriba aquí su consulta..."
                        statusMessage="hola@applite.cl"
                        avatar="/assets/logo/wsp_applite.png"
                        /*chatboxHeight="290px"*/
                        notificationDelay="5"
                        messageDelay="5"
                        allowClickAway={true}
                        notificationSound={true}/>
  );
}